@font-face {
  font-family: Nexa;
  src: url(../../../assets/fonts/Nexa-Regular.ttf);
}

$primaryColor: #662d91 !default;
$secondaryColor: #7ec141 !default;
$blackText: #38221e !default;
$yellow: #f7b500 !default;
$grey: #9b9ea3 !default;
$darkGrey: #667085 !default;
$lightGrey: #fafbfc !default;
$green: #83c372 !default;
$lightBackground: #efeffd !default;
$greenBackground: #e7f4ee !default;
$red: #e54e4e !default;
.primary {
  background-color: $primaryColor;
}
body,
html {
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Nexa";
}

span {
  font-family: "Nexa";
}
p {
  font-family: "Nexa";
}
h1 {
  font-family: "Nexa";
}
h2 {
  font-family: "Nexa";
}
h3 {
  font-family: "Nexa";
}
h4 {
  font-family: "Nexa";
}
h5 {
  font-family: "Nexa";
}
h6 {
  font-family: "Nexa";
}
a {
  font-family: "Nexa";
}
button {
  font-family: "Nexa";
}
